import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import Patient from '@/views/Patient.vue'
import Organization from '@/views/Organization.vue'
import User from '@/views/User.vue'
import Access from '@/views/Access.vue'
import Analysis from '@/views/Analysis.vue'
import PatientProject from '@/views/PatientProject.vue'
import Study from '@/views/Study.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/patient',
        name: 'patient',
        component: Patient
    },
    {
        path: '/organization',
        name: 'organization',
        component: Organization
    },
    {
        path: '/user',
        name: 'user',
        component: User
    },
    {
        path: '/access',
        name: 'access',
        component: Access
    },
    {
        path: '/analysis',
        name: 'analysis',
        component: Analysis
    },
    {
        path: '/patient-project',
        name: 'patient-project',
        component: PatientProject
    },
    {
        path: '/study',
        name: 'study',
        component: Study
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
