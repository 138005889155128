



















import { Component, Vue } from 'vue-property-decorator'

@Component({})

export default class Home extends Vue {
  buttons: Array<{ name: string, color: string }> = [
      { name: 'organization', color: 'blue' },
      { name: 'user', color: 'blue' },
      { name: 'patient', color: 'blue' },
      { name: 'access', color: 'red' },
      { name: 'analysis', color: 'red' },
      { name: 'patient-project', color: 'red' },
      { name: 'study', color: 'red' }
  ]

  goTo (name: string): void {
      this.$router.push({ name })
  }
}
